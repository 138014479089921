<template>
    <div>
        <Navbar />
        <v-row justify="center">
            <v-col cols=12 md=6 class="mx-1 text-justify px-8">
                <div class="headline">Súhlas so spracúvaním osobných údajov</div>
                <br>
                <div class="subtitle-1">v zmysle článku 6 ods. 1 a článku 7 Nariadenia Európskeho parlamentu a rady (EÚ) 2016/679</div>
                <br>
                <p>
                Týmto súhlasím so spracúvaním osobných údajov v rozsahu údajov uvedených v profile dobrovoľníka/organizácie žiadajúcej dobrovoľnícku prácu. Osobné údaje sa spracúvajú za účelom evidencie dobrovoľníkov a dobrovoľníckych prác. Kontaktné údaje môžu byť poskytnuté prostredníctvom aplikácie osobám a organizáciám, ktoré v tejto súvislosti potrebujú pomoc.
                </p>
                Svoj súhlas udeľujem prevádzkovateľovi: <br>
                KAJO s .r. o. <br>
                01401 Bytca <br>
                Sladkovicova 228/8 <br>
                SLOVENSKO <br>
                IČO: 46510052 <br>
                IČ DPH: SK2023440848 <br> <br>
                Zodpovedná / kontaktná osoba: <br>
                Milan Kalas <br> <br>
                v rámci aplikácie Helfni. <br> <br>
                Osobné údaje budú uchovávané po dobu trvania registrácie v aplikácii. <br> <br>
                Účelom spracúvania osobných údajov je evidovanie dobrovoľníkov a dobrovoľníckych prác v rámci aplikácie Helfni. Spracúvanie sa vykonáva na základe súhlasu dotknutej osoby. Súhlas dotknutej osoby, ako aj poskytnutie pomoci je dobrovoľné. Dotknutá osoba má právo súhlas kedykoľvek odvolať, odvolanie súhlasu nemá vplyv na zákonnosť spracúvania osobných údajov založeného na súhlase udelenom pred jeho odvolaním. Osobné údaje sa poskytujú osobám, ktoré potrebujú pomoc prostredníctvom aplikácie. <br><br>
                Vaše informácie, vrátane osobných údajov, môžu byť prenesené a uchovávané na počítačoch nachádzajúcich sa mimo vášho mesta, kraju či štátu alebo na území inej vládnej jurisdikcie, v ktorej sa zákony týkajúce sa ochrany osobných údajov môžu líšiť od tých vo vašej vládnej jurisdikcii. Ak sa nachádzate mimo územia Slovenskej republiky a rozhodnete sa poskytnúť nám vaše informácie, prosím berte na vedomie, že tieto informácie, vrátane osobných údajov, budú prenesené na územie Slovenskej republiky, kde budú následne spracované. Váš súhlas nasledovaný vaším odovzdaním takýchto informácií je považovaný za váš súhlas s týmto presunom. <br> <br>
                Prevádzkovateľ učiní všetky možné kroky na zaistenie toho, aby sa s vašimi informáciami nakladalo bezpečne a v súlade s týmto súhlasom a taktiež, že žiadny presun vašich osobných údajov sa nevykoná do inej organizácie alebo krajiny, pokiaľ v tejto organizácii alebo krajine neplatia podobné kontrolné mechanizmy týkajúce sa bezpečnosti vašich informácií a osobných údajov. <br> <br>
                Prevádzkovateľ nevykonáva ani spracúvanie osobných údajov založené na automatizovanom individuálnom rozhodovaní, nevykonáva profilovanie. <br> <br>
                Osobné údaje nie sú spracúvané za iným účelom, ako za účelom, na ktorý boli pôvodne získané, ak tak neustanoví osobitný predpis, alebo ak dotknutá osoba na to neudelí dobrovoľný súhlas. <br> <br>
                V súlade s čl. 15 až 22 Nariadenia EÚ dotknutá osoba je oprávnená uplatniť práva dotknutých osôb, najmä právo na potvrdenie o spracúvaní osobných údajov, právo na prístup k osobným údajom a informáciám týkajúcich sa spracúvania osobných údajov, právo na opravu nesprávnych a právo na doplnenie neúplných osobných údajov, právo na obmedzenie spracúvania osobných údajov, právo na prenosnosť, právo na výmaz osobných údajov. Svoje práva si dotknutá osoba môže uplatniť najmä prostredníctvom kontaktných údajov prevádzkovateľa uvedených vyššie alebo prostredníctvom emailovej adresy helfni@helfni.sk alebo sdisk@sdisk.eu. <br> <br>
                Ak dotknutá osoba má akékoľvek otázky súvisiace so spracúvaním jej osobných údajov, má právo kedykoľvek kontaktovať prevádzkovateľa. Ak sa dotknutá osoba domnieva, že pri spracúvaní osobných údajov boli porušené jej práva chránené nariadením a zákonom o ochrane osobných údajov, má právo podať na Úrad na ochranu osobných údajov SR, Hraničná 12, Bratislava sťažnosť, resp. návrh na začatie konania. <br> <br>
                Prevádzkovateľ si vyhradzuje právo používať poskytovateľov služieb tretej strany na monitorovanie a analýzu využívania aplikácie Helfni. <br> <br>
                <span class="title">Google Analytics</span> <br> <br>
                Google Analytics je webová analytická služba poskytovaná spoločnosťou Google, ktorá sleduje a nahlasuje prenos dát na internete. Google používa zhromaždené dáta na sledovanie a monitorovanie využívania stránky. Tieto dáta sú zdieľané s inými službami spoločnosti Google. Google môže využívať tieto dáta na personalizáciu reklám jeho vlastnej reklamnej siete. <br> <br>
                Odmietnuť sprístupňovanie vašich aktivít na našej stránke spoločnosti Google Analytics viete nainštalovaním Google Analytics opt-out add-onu na váš prehliadač. Tento add-on zabraňuje JavaScript kódu služby Google Analytics (ga.js, analytics.js, dc.js a gtag.js) zdieľať informácie službe Google Analytics o vašej aktivite. <br> <br>
                Pre viac informácií o ochrane súkromia spoločnosti Google navštívte prosím stránku obchodných podmienok spoločnosti Google. https://policies.google.com/privacy?hl=en <br> <br>
                <span class="title">Zmeny súhlasu o spracúvaní osobných údajov</span> <br> <br>
                Tento súhlas o spracúvaní osobných údajov môže byť v budúcnosti predmetom zmeny. O každých takýchto zmenách vás budeme informovať uverejnením nového súhlasu so spracúvaním osobných údajov na túto stránku. Taktiež vás prostredníctvom emailu a/alebo zreteľným oznámením na našej stránke s takýmito zmenami oboznámime pred ich vstupom do platnosti. <br> <br>
                Prehlasujem, že som bol/a poučený/á o dobrovoľnosti poskytnutia tohto súhlasu a tak ho aj dávam. Súhlas som dal/a zo svojej slobodnej vôle bez akéhokoľvek nátlaku a bez akýchkoľvek podmienok zo strany prevádzkovateľa nesúvisiacich s mojím súhlasom. <br> <br>
                <div class="headline">Podmienky používania</div>
                <br>
                <p>Užívateľ sa zaväzuje poskytovať iba pravdivé a úplné informácie. Žiaden obsah vytvorený užívateľom nesmie vytvárať klamlivú predstavu o organizácii, dobrovoľníkovi alebo ponuke, nesmie byť neslušný, urážlivý alebo iným spôsobom nevhodný.</p>
                <p>Užívateľ nesmie bez povolenia prevádzkovateľa zdieľať svoje prístupové údaje, prípadne poskytovať svoj účet iným osobám, v prípade organizácií osobám mimo túto organizáciu.</p>
                <p>Prevádzkovateľ si vyhradzuje právo kontrolovať všetok obsah vytvorený užívateľmi a taktiež si vyhradzuje právo akýkoľvek takýto obsah, najmä vytvorené ponuky a užívateľské účty, zmazať v prípade, že sa tento obsah nebude zhodovať s hlavnými cieľmi služby alebo bude prevádzkovateľom vyhodnotený za nevhodný.</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import Navbar from '@/components/partials/Navbar'

    export default {
        name: "Terms",
        components: {Navbar}
    }
</script>

<style lang="scss" scoped>

</style>
